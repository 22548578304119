import PropTypes from 'prop-types';
import { withFold } from 'bv-hocs';
import CompetitionView from './competition_view';

const INITIAL_EVENTS_TO_DISPLAY = 5;

const CompetitionContainer = ({
  description,
  events,
  folded,
  toggleFolded,
}) => {
  const displayShowMore = events.length > INITIAL_EVENTS_TO_DISPLAY && folded;
  const eventsToDisplay = displayShowMore ? INITIAL_EVENTS_TO_DISPLAY : events.length;

  return (
    <CompetitionView
      description={description}
      eventsToDisplay={events.slice(0, eventsToDisplay)}
      totalEventsLength={events.length}
      displayShowMore={displayShowMore}
      toggleFolded={toggleFolded}
    />
  );
};

CompetitionContainer.propTypes = {
  description: PropTypes.string.isRequired,
  events: PropTypes.instanceOf(Array).isRequired,
  folded: PropTypes.bool.isRequired,
  toggleFolded: PropTypes.func.isRequired,
};

export default withFold(CompetitionContainer);
