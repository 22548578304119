import PropTypes from 'prop-types';

const DaySelectorView = ({
  weekDay,
  monthDay,
  active,
  setActive,
}) => (
  <li
    className={`bvs-button-chip ${active ? 'active' : ''} `}
    onClick={setActive}
  >
    <span className="lb-fixtures-slider-weekday">{weekDay}</span>
    <span className="lb-fixtures-slider-date">{monthDay}</span>
  </li>
);

DaySelectorView.propTypes = {
  weekDay: PropTypes.string.isRequired,
  monthDay: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
};

export default DaySelectorView;
