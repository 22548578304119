import PropTypes from 'prop-types';
import { Icon } from 'bv-components';
import { Competitions } from './competitions';

const Sport = ({ id, description, competitions }) => (
  <div>
    <h2 className="lb-fixtures-sport-title bvs-h4 bvs-ellipsis">
      <Icon id={`sport-${id}`} big type="leaderboard-piu-events-sport-icon" />
      {description}
    </h2>

    <Competitions competitions={competitions} />
  </div>
);

Sport.propTypes = {
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  competitions: PropTypes.instanceOf(Array).isRequired,
};

export default Sport;
