import PropTypes from 'prop-types';
import { Sport } from './sport';

const Day = ({ day }) => (
  <div>
    <div className="lb-fixtures-sport-card-wrapper">
      {
        day.sports.map((sport) => (
          <Sport
            id={sport.sportId}
            description={sport.sportDescription}
            competitions={sport.competitions}
          />
        ))
      }
    </div>
  </div>
);

Day.propTypes = {
  day: PropTypes.instanceOf(Object).isRequired,
};

export default Day;
