/* eslint-disable max-len */
import { isArray, isObject, groupBy } from 'underscore';
import { locale } from 'bv-i18n';

const TIERS = {
  'ENG-PREMIER-LEAGUE': 1,
  'UEFA-NATIONS-LEAGUE': 2,
  'EURO-2024-MATCHES': 2,
  'COPA-AMERICA': 2,
  'CHAMPIONS-LEAGUE': 2,
  'EUROPA-LEAGUE': 2,
  'UEFA-EUROPA-CONFERENCE-LEAGUE': 2,
  'ENG-FA-CUP': 2,
  'ENG-EFL-CUP': 2,
  'ENG-LEAGUE-CHAMPIONSHIP': 2,
  'ENG-LEAGUE-ONE': 2,
  'ENG-LEAGUE-TWO': 2,
  'ENG-COMMUNITY-SHIELD': 2,
  'ENG-EFL-TROPHY': 2,
  'SCO-PREMIER': 2,
  'CHN-CHINA-SUPER-LEAGUE': 2,
  'CHN-CHINA-CUP': 2,
  'ITA-SERIE-A': 2,
  'ITA-COPPA-ITALIA': 2,
  'ITA-SUPER-CUP': 2,
  'FRA-LIGUE-1': 2,
  'FRA-FRENCH-CUP': 2,
  'ESP-LA-LIGA-PRIMERA': 2,
  'ESP-COPA-DEL-REY': 2,
  'ESP-SUPER-CUP': 2,
  'GER-BUNDESLIGA': 2,
  'CLUB-FRIENDLY': 3,
  'INT-FRIENDLIES': 3,
  'POR-PRIMEIRA-LIGA': 3,
  'POR-TACA-DA-LIGA-CUP': 3,
  'NED-EREDIVISIE': 3,
  'BEL-JUPILER': 3,
  'TUR-SUPER-LIG': 3,
  'TUR-TURKISH-CUP': 3,
  'AUS-A-LEAGUE': 3,
  'CHN-JIA-LEAGUE': 3,
  'JAP-J-1-LEAGUE': 3,
  'USA-MAJOR-LEAGUE-SOCCER': 3,
  'BRA-SERIE-A-BRASILEIRO': 3,
  'ARG-PRIMERA-DIVISION': 3,
  'MEX-LIGA-DE-ASCENSO': 3,
  'MEX-MEXICAN-PRIMERA': 3,
  'MEX-COPA-MEXICO': 3,
  'ITA-SERIE-B': 3,
  'ESP-LA-LIGA-SEGUNDA': 3,
  'FRA-LIGUE-2': 3,
  'GER-BUNDESLIGA-2': 3,
  'NED-JUPILER-LEAGUE': 3,
  'ENG-NATIONAL-LEAGUE': 3,
  'SCO-LEAGUE-CUP': 3,
  'SCO-CHAMPIONSHIP': 3,
  'SCO-LEAGUE-1': 3,
  'SCO-LEAGUE-2': 3,
  'SCO-SCOTTISH-FA-CUP': 3,
};

const getTier = (event) => TIERS[event.competitionDimension] || Number.MAX_SAFE_INTEGER;

const compareByDate = (event1, event2) => event1.date - event2.date;

const compareByTiers = (event1, event2) => getTier(event1) - getTier(event2);

const compareEvents = (event1, event2) => compareByTiers(event1, event2) || compareByDate(event1, event2);

const toCompetition = (events) => ({
  competitionDimension: events[0].competitionDimension,
  competitionDescription: events[0].competitionDescription,
  events,
});

const toSport = (events) => ({
  sportId: events[0].sportId,
  sportDimension: events[0].sportDimension,
  sportDescription: events[0].sportDescription,
  competitions: Object.values(groupBy(events, 'competitionDimension')).map(toCompetition),
});

const toDay = (events) => ({
  weekDay: events[0].weekDay,
  monthDay: events[0].monthDay,
  sports: Object.values(groupBy(events, 'sportDimension')).map(toSport),
});

const valid = (eventsResponse) => isArray(eventsResponse)
  && isObject(eventsResponse[0])
  && isArray(eventsResponse[0].children);

export default (eventsResponse) => {
  if (!valid(eventsResponse)) return [];

  // Convert the tree into a plain list that we can easily sort by date
  // each item will be an event where we will include its competition and Sport
  const flatEvents = eventsResponse.reduce((sportAcc, sport) => ([
    ...sportAcc,
    ...sport.children.reduce((competitionAcc, competition) => ([
      ...competitionAcc,
      ...competition.children.map((event) => {
        const eventDate = new Date(event.startTime);

        return {
          ...event,
          date: eventDate,
          monthDay: eventDate.toLocaleDateString(locale(), { day: 'numeric', month: 'long' }),
          weekDay: eventDate.toLocaleDateString(locale(), { weekday: 'short' }),
          sportDimension: sport.dimension_value,
          sportDescription: sport.description,
          competitionDimension: competition.dimension_value,
          competitionDescription: competition.description,
        };
      }),
    ]), []),
  ]), []);

  const sortedByDate = flatEvents.sort(compareByDate);

  // Group to preserve iteration order. First order by date and then reorder by tier + date.
  const groupedByDate = sortedByDate.reduce((acc, event) => ({
    ...acc,
    [event.monthDay]: [...(acc[event.monthDay] || []), event].sort(compareEvents),
  }), {});

  // Build new tree preserving the order and grouping per Date / Sport / Competition
  return Object.values(groupedByDate).map(toDay);
};
