import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import { t } from 'bv-i18n';
import { NoEvents } from './no_events';
import { DaysSelector } from './days_selector';
import { Day } from './day';

const AppView = ({
  days,
  activeDayIndex,
  setActiveDayIndex,
  loading,
}) => {
  if (loading) return <Spinner />;
  if (!days || days.length === 0) return <NoEvents />;

  return (
    <div className="lb-fixtures-container">
      <h1 className="bvs-header">
        {t('javascript.leaderboard.fixtures.title')}
      </h1>

      <DaysSelector
        days={days}
        activeDayIndex={activeDayIndex}
        setActiveDayIndex={setActiveDayIndex}
      />

      <Day day={days[activeDayIndex]} />
    </div>
  );
};

AppView.propTypes = {
  days: PropTypes.instanceOf(Object).isRequired,
  activeDayIndex: PropTypes.number.isRequired,
  setActiveDayIndex: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AppView;
