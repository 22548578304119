import PropTypes from 'prop-types';
import { Carousel } from 'bv-components';
import { DaySelector } from './day_selector';

const DaysSelectorView = ({ days, activeDayIndex, setActiveDayIndex }) => (
  <Carousel
    scrollStep={234}
    className="lb-fixtures-slider"
    embedded
  >
    {
      days.map((day, index) => (
        <DaySelector
          weekDay={day.weekDay}
          monthDay={day.monthDay}
          active={activeDayIndex === index}
          setActive={() => setActiveDayIndex(index)}
        />
      ))
    }
  </Carousel>
);

DaysSelectorView.propTypes = {
  days: PropTypes.instanceOf(Array).isRequired,
  activeDayIndex: PropTypes.number.isRequired,
  setActiveDayIndex: PropTypes.func.isRequired,
};

export default DaysSelectorView;
