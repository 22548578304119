/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { Link } from 'bv-components';

const pad0 = (element) => (`0${element}`).slice(-2);
const eventHour = (startTime) => {
  const date = new Date(startTime);
  const hour = pad0(date.getHours());
  const minute = pad0(date.getMinutes());

  return `${hour}:${minute}`;
};

const Event = ({
  eventId,
  opponentADescription,
  opponentBDescription,
  startTime,
}) => (
  <Link to={`/bet-builder-launcher/${eventId}?clickSource=leaderboard-bb-quick-launch`}>
    <div className="lb-fixtures-container__row bvs-link">
      <span className="lb-fixtures-container__opponentA">
        <span className="lb-fixtures-container__description">
          {opponentADescription}
        </span>
      </span>
      <span className="lb-fixtures-container__time">
        {eventHour(startTime)}
      </span>
      <span className="lb-fixtures-container__opponentB">
        <span className="lb-fixtures-container__description">
          {opponentBDescription}
        </span>
      </span>
    </div>
  </Link>
);

Event.propTypes = {
  eventId: PropTypes.number.isRequired,
  opponentADescription: PropTypes.string.isRequired,
  opponentBDescription: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired,
};

export default Event;
