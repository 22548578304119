import { useState } from 'react';
import { useFetch } from 'bv-hooks';
import { fetchBetBuilderEvents } from 'BetBuilderFixtures/api';
import { groupEvents } from 'BetBuilderFixtures/services';
import AppView from './app_view';

const fetchDays = () => fetchBetBuilderEvents().then(groupEvents);

const AppContainer = () => {
  const [activeDayIndex, setActiveDayIndex] = useState(0);
  const [days, loading] = useFetch(fetchDays, []);

  return (
    <AppView
      days={days}
      activeDayIndex={activeDayIndex}
      setActiveDayIndex={setActiveDayIndex}
      loading={loading}
    />
  );
};

AppContainer.displayName = 'BetBuilderFixturesApp';

export default AppContainer;
