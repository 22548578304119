import PropTypes from 'prop-types';
import { withFold } from 'bv-hocs';
import { t } from 'bv-i18n';
import { Events } from './events';

const Competition = ({
  description,
  eventsToDisplay,
  totalEventsLength,
  displayShowMore,
  toggleFolded,
}) => (
  <li>
    <h2 className="bvs-card-header lb-fixtures-container__card-header">
      {description}
    </h2>

    <Events events={eventsToDisplay} />

    {
      displayShowMore && (
        <div onClick={toggleFolded} className="lb-fixtures-container__showmore-wrapper">
          <h2 className="lb-fixtures-container__showmore bvs-link">
            {
              t('javascript.more_elements_of_remaining', {
                elements: totalEventsLength - eventsToDisplay.length,
                remaining: totalEventsLength,
              })
            }
          </h2>
        </div>
      )
    }
  </li>
);

Competition.propTypes = {
  description: PropTypes.string.isRequired,
  eventsToDisplay: PropTypes.instanceOf(Array).isRequired,
  totalEventsLength: PropTypes.number.isRequired,
  displayShowMore: PropTypes.bool.isRequired,
  toggleFolded: PropTypes.func.isRequired,
};

export default withFold(Competition);
