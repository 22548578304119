import PropTypes from 'prop-types';
import { Event } from './event';

const Events = ({ events }) => (
  <ul className="lb-fixtures-container__card-content">
    {
      events.map((event) => (
        <Event
          key={event.id}
          eventId={event.id}
          opponentADescription={event.opponentADescription}
          opponentBDescription={event.opponentBDescription}
          startTime={event.startTime}
        />
      ))
    }
  </ul>
);

Events.propTypes = {
  events: PropTypes.instanceOf(Array).isRequired,
};

export default Events;
