import PropTypes from 'prop-types';
import { Competition } from './competition';

const CompetitionsView = ({ competitions }) => (
  <ul className="bvs-card lb-fixtures-container__card">
    {
      competitions.map((competition) => (
        <Competition
          description={competition.competitionDescription}
          events={competition.events}
        />
      ))
    }
  </ul>
);

CompetitionsView.propTypes = {
  competitions: PropTypes.instanceOf(Array).isRequired,
};

export default CompetitionsView;
